/* Set content for font-families */
.ql-font-arial,
.ql-font span[data-value="arial"]::before {
  font-family: Arial, sans-serif;
}
.ql-font-comic-sans,
.ql-font span[data-value="comic-sans"]::before {
  font-family: "Comic Sans MS", cursive, sans-serif;
}
.ql-font-courier-new,
.ql-font span[data-value="courier-new"]::before {
  font-family: "Courier New";
}
.ql-font-georgia,
.ql-font span[data-value="georgia"]::before {
  font-family: Georgia, serif;
}
.ql-font-helvetica,
.ql-font span[data-value="helvetica"]::before {
  font-family: Helvetica, sans-serif;
}
.ql-font-lucida,
.ql-font span[data-value="lucida"]::before {
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
}

/*Set content for font size*/
.ql-size-20px,
.ql-size span[data-value="20px"]::before {
  font-size: "20px";
}

.ql-size-17px,
.ql-size span[data-value="17px"]::before {
  font-size: "17px";
}

.ql-size-15px,
.ql-size span[data-value="15px"]::before {
  font-size: "15px";
}

.ql-size-13px,
.ql-size span[data-value="13px"]::before {
  font-size: "13px";
}

.ql-size-11px,
.ql-size span[data-value="11px"]::before {
  font-size: "11px";
}

.ql-size-9px,
.ql-size span[data-value="9px"]::before {
  font-size: "9px";
}
