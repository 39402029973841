@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500&display=swap");
@import url("https://fonts.cdnfonts.com/css/mosk");

html,
body {
  /* display: flex; */
  padding: 0;
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  width: 100%;
  height: 100%;
  background: transparent !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  font-family: "Montserrat", sans-serif !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#__next {
  width: 100%;
  height: 100%;
  background: transparent !important;
}

/* @media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
} */

/* Set content for font-families */
.ql-font-arial,
.ql-font span[data-value="arial"]::before {
  font-family: Arial, sans-serif;
}
.ql-font-comic-sans,
.ql-font span[data-value="comic-sans"]::before {
  font-family: "Comic Sans MS", cursive, sans-serif;
}
.ql-font-courier-new,
.ql-font span[data-value="courier-new"]::before {
  font-family: "Courier New";
}
.ql-font-georgia,
.ql-font span[data-value="georgia"]::before {
  font-family: Georgia, serif;
}
.ql-font-helvetica,
.ql-font span[data-value="helvetica"]::before {
  font-family: Helvetica, sans-serif;
}
.ql-font-lucida,
.ql-font span[data-value="lucida"]::before {
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
}

/*Set content for font size*/
.ql-size-20px,
.ql-size span[data-value="20px"]::before {
  font-size: "20px";
}

.ql-size-17px,
.ql-size span[data-value="17px"]::before {
  font-size: "17px";
}

.ql-size-15px,
.ql-size span[data-value="15px"]::before {
  font-size: "15px";
}

.ql-size-13px,
.ql-size span[data-value="13px"]::before {
  font-size: "13px";
}

.ql-size-11px,
.ql-size span[data-value="11px"]::before {
  font-size: "11px";
}

.ql-size-9px,
.ql-size span[data-value="9px"]::before {
  font-size: "9px";
}

.eventBox {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.eventBox::-webkit-scrollbar {
  width: 5px;
}
.eventBox::-webkit-scrollbar-thumb {
  background: #1f6521;
  border-radius: 5px;
}

