.eventBox {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.eventBox::-webkit-scrollbar {
  width: 5px;
}
.eventBox::-webkit-scrollbar-thumb {
  background: #1f6521;
  border-radius: 5px;
}
